@import '~antd/dist/antd.css';

.content {
    width: 90%;
    margin: auto;
}

.table-row {
    cursor: pointer;
}

.markings-card {
    width: 100%;
    height: 400px;
    text-align: center;

    .markings-card-cover {
        width: 100%;
        height: 250px;
        img {
            padding: 10px;
            margin-left: auto;
            margin-right: auto;
            max-height: 100%;
            max-width: 100%;
        }
    }
}

.product-values-panels {
    .ant-collapse-header {
        display: flex;
        align-items: center;
    }

    .ant-collapse-extra {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }
}
